* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --c-blue: #2342e2;
  --c-grey-1: #8289af;
  --c-grey-2: #aeb3d2;
  --c-grey-3: #efeff4;
  --c-black: #1d1d1d;

  --speed-quick: 0.3s;
  --speed-default: 0.5s;
}

html {
  color: var(--c-black);
  font-size: 1em;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
}

::-moz-selection {
  background: var(--c-blue);
  color: white;
  text-shadow: none;
}

::selection {
  background: var(--c-blue);
  color: white;
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/** fonts **/
@font-face {
  font-family: "qooa-font";
  src:
    url("../fonts/KUniforma-70Semibold.woff") format("woff"),
    url("../fonts/KUniforma-70Semibold.woff2") format("woff2"),
    url("../fonts/desktop/KUniforma-70Semibold.otf") format("otf");
}

body {
  font-family: "qooa-font";
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background-color: var(--c-grey-3);
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/** colors **/
.color-blue {
  color: var(--c-blue);
}

.color-grey1 {
  color: var(--c-grey-1);
}

.color-grey2 {
  color: var(--c-grey-2);
}

.color-grey3 {
  color: var(--c-grey-3);
}

.color-black {
  color: var(--c-black);
}

.bck-color-blue {
  background-color: var(--c-blue);
}

.bck-color-grey1 {
  background-color: var(--c-grey-1);
}

.bck-color-grey2 {
  background-color: var(--c-grey-2);
}

.bck-color-grey3 {
  background-color: var(--c-grey-3);
}

.bck-color-black {
  background-color: var(--c-black);
}

/** global **/

h1,
h2,
h3 {
  margin: 0;
  font-weight: 600;
}

h1 {
  font-size: 72px;
  line-height: 110%;
}

h1 span {
  font-size: 24px;
  line-height: 125%;
}

h2 {
  font-size: 48px;
  line-height: 110%;
  margin-bottom: 6rem;
}

h3 {
  font-size: 32px;
  line-height: 120%;
}

blockquote {
  font-size: 40px;
  line-height: 120%;
}

body,
p {
  font-size: 20px;
  line-height: 145%;
  font-weight: 400;
}

p {
  font-family: "Poppins", sans-serif;
}

/** desktop **/
@media screen and (max-width: 1440px) {
  h1 {
    font-size: 64px;
  }
  h1 span {
    font-size: 20px;
  }
  h2 {
    font-size: 40px;
    margin-bottom: 3.5rem;
  }
  h3 {
    font-size: 24px;
  }
  blockquote {
    font-size: 32px;
  }
  p {
    font-size: 18px;
  }
}

/** mobile **/
@media screen and (max-width: 896px) {
  h1 {
    font-size: 32px;
  }
  h1 span {
    font-size: 16px;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 3rem;
  }
  h3 {
    font-size: 18px;
  }
  blockquote {
    font-size: 20px;
  }
  p {
    font-size: 15px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

/** content **/

html {
  height: 100%;
  overflow: hidden;
}
body {
  height: 100%;
  overflow: hidden;
}
body.scrolled {
  overflow: auto;
}

main {
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
}

.logo-wrapper {
  color: var(--c-grey-3);
  width: 163px;
  transition: width var(--speed-default) ease-in-out;
  display: inline-block;
  z-index: 15;
}

.logo-wrapper svg {
  width: 100%;
  height: 100%;
}

body.scrolled .logo-wrapper {
  width: 100%;
}

.main-animation {
  width: 100%;
  left: 50%;
  bottom: -5vh;
  transform: translate(-50%, 70%);
  transition:
    bottom var(--speed-default) ease-in-out,
    width var(--speed-default) ease-in-out;
  position: absolute;
}

body.scrolled .main-animation {
  bottom: 50vh;
  width: 60%;
}

.mobile-navbar {
  background-color: var(--c-black);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  z-index: 10;
  transform: translateY(-100%);
  transition: transform var(--speed-quick) ease-in-out;
  display: none;
}

body.scrolled .mobile-navbar {
  transform: translateY(0);
}

.main-content {
  display: flex;
  flex-wrap: nowrap;
}

.main-content .content-section {
  margin-bottom: 10rem;
}

.main-content .left {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: hidden;
  flex: 0 0 100%;
  transition: flex var(--speed-default) ease-in-out;
  padding: 2.75rem;
}

body.scrolled .main-content .left {
  flex: 0 0 50%;
}

.main-content .contact {
  text-decoration: none;
  padding: 1rem 1.5rem;
  border-radius: 80px;
  font-size: 18px;
  line-height: 125%;
  position: absolute;
  right: 2.75rem;
  top: 2.75rem;
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition:
    opacity var(--speed-quick) ease-in-out,
    visibility var(--speed-quick) ease-in-out,
    top var(--speed-quick) ease-in-out,
    transform var(--speed-quick) ease-in-out;
  z-index: 15;
  display: inline-block;
}

.main-content .contact .icon {
  display: none;
}

.main-content .contact .text {
  display: block;
}

.main-content .contact:hover {
  transform: scale(1.03);
}

body.scrolled .main-content .contact {
  opacity: 0;
  visibility: hidden;
}

.main-content .left h1 {
  text-align: center;
  width: 850px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition:
    opacity var(--speed-quick) ease-in-out,
    visibility var(--speed-quick) ease-in-out;
  opacity: 1;
  visibility: visible;
}

body.scrolled .main-content .left h1 {
  opacity: 0;
  visibility: hidden;
}

.main-content .left h1 span {
  display: block;
  margin-bottom: 20px;
}

.main-content .left .pronuncia {
  font-size: 40px;
  line-height: 110%;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition:
    opacity var(--speed-quick) ease-in-out,
    visibility var(--speed-quick) ease-in-out;
  width: 100%;
  text-align: center;
}

body.scrolled .main-content .left .pronuncia {
  opacity: 1;
  visibility: visible;
}

.main-content .right {
  flex: 0 0 50%;
  transition: transform var(--speed-default) ease-in-out;
  transform: translateX(100%);
}

body.scrolled .main-content .right {
  transform: translateX(0%);
}

.main-content .right > div {
  padding: 2.75rem 2.75rem 0 2.75rem;
  transform: translateY(300px);
  transition: transform 1s ease-in-out;
}

.main-content .right > div:first-child {
  padding-top: 6rem;
}

.main-content .right > div:not(:first-child) {
  padding-top: 0;
}

body.scrolled .main-content .right > div {
  transform: translateY(0);
}


@media screen and (max-width: 896px) {
  .main-content .content-section {
    margin-bottom: 5rem;
  }
  .main-content .left {
    padding: 1rem;
  }
  body.scrolled .main-content .left {
    flex: 0 0 35%;
  }
  .main-content .right {
    flex: 0 0 65%;
  }
  .main-content .right > div {
    padding: 1.5rem 1.5rem 0 1.5rem;
  }
  .main-content .right > div:first-child {
    padding-top: 1.5rem;
  }
  .logo-wrapper {
    width: 90px;
  }
  .main-animation {
    bottom: 0;
  }
  body.scrolled .main-animation {
    width: 90%;
    bottom: 80vh;
  }
  .main-content .contact {
    font-size: 12px;
    padding: 0.5rem 1rem;
    right: 1rem;
    top: 1rem;
  }
  .main-content .left h1 {
    width: 400px;
  }
  .main-content .left .pronuncia {
    font-size: 24px;
  }
}

@media screen and (max-width: 600px) {
  .mobile-navbar {
    display: block;
  }
  .main-content .contact {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    padding: 0;
    background-color: transparent;
    font-size: 16px;
    gap: 8px;
  }
  .main-content .contact .icon {
    display: block;
  }

  .main-content .contact .text {
    display: none;
  }

  body.scrolled .main-content .contact {
    opacity: 1;
    visibility: visible;
  }
  .logo-wrapper {
    width: calc(100% - 32px);
    position: fixed;
  }
  body.scrolled .logo-wrapper {
    width: 90px;
  }
  .main-content {
    flex-direction: column;
    background-color: var(--c-grey-3);
  }
  .main-content .left,
  body.scrolled .main-content .left {
    position: relative;
    height: 95vh;
    flex: none;
    background-color: var(--c-blue);
  }
  .main-animation,
  body.scrolled .main-animation {
    top: 45%;
    bottom: auto;
    transform: translate(-50%, -50%);
    width: 90%;
  }
  .main-content .left h1,
  body.scrolled .main-content .left h1 {
    transform: translateX(-50%);
    left: 50%;
    top: auto;
    bottom: 64px;
    width: 100%;
    max-width: 360px;
    opacity: 1;
    visibility: visible;
  }

  .main-content .left h1 span {
    margin-bottom: 12px;
  }

  body.scrolled .main-content .left .pronuncia {
    display: none;
  }

  .main-content .right,
  body.scrolled .main-content .right {
    transform: none;
  }

  .main-content .right > div:first-child {
    padding-top: 4rem;
  }
}

.accordion {
  border-bottom: 1px solid var(--c-black);
}

.accordion h3 {
  padding: 1rem 0;
  border-top: 1px solid var(--c-black);
}

.accordion-trigger {
  background: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;
  cursor: pointer;
  border-style: none;
  padding: 1rem 0;
  color: var(--c-blue);
}

.accordion-trigger:focus-visible {
  outline: 2px solid var(--c-blue);
  outline-offset: 4px;
  border-radius: 8px;
}

.accordion-trigger[aria-expanded="true"] {
  padding-bottom: 1rem;
  color: var(--c-black);
}

.accordion-title {
  display: block;
  flex: 1;
  max-width: 80%;
}

.accordion-icon {
  flex: 0 0 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background-color: var(--c-black);
  margin-top: 0.5rem;
  position: relative;
  display: inline-block;
  justify-self: flex-end;
}

.accordion-icon:before {
  position: absolute;
  content: "";
  width: 12px;
  height: 2px;
  background-color: var(--c-grey-3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.accordion-icon:after {
  position: absolute;
  content: "";
  width: 2px;
  height: 12px;
  background-color: var(--c-grey-3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.accordion-trigger[aria-expanded="true"] .accordion-icon:after {
  opacity: 0;
}

.accordion-panel p {
  margin: 0;
  margin-top: -1rem;
  margin-bottom: 2rem;
  max-width: 80%;
}

@media screen and (max-width: 896px) {
  .accordion h3 {
    padding: 0.5rem 0;
  }
  .accordion-trigger {
    padding: 0.5rem 0;
  }
  .accordion-icon {
    flex: 0 0 1.25rem;
    height: 1.25rem;
  }
  .accordion-icon:before {
    width: 10px;
  }
  .accordion-icon:after {
    height: 10px;
  }
  .accordion-panel p {
    max-width: 90%;
    margin-bottom: 1rem;
  }
}

ol {
  list-style: none;
  counter-reset: item;
  padding: 0;
}
ol li {
  counter-increment: item;
  display: flex;
  position: relative;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid var(--c-black);
  margin-bottom: 2.5rem;
}

ol li > div:first-child {
  margin-right: 2rem;
}

ol li img {
  max-width: 180px;
}

ol li p {
  margin: 0;
  margin-top: 1rem;
}

ol li:before {
  content: counter(item);
  flex: 0 0 2.75rem;
  text-align: center;
  display: inline-flex;
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  color: var(--c-grey-1);
}

@media screen and (max-width: 896px) {
  ol li {
    display: inline-block;
    padding-left: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }
  ol li p {
    margin-top: 0.5rem;
  }
  ol li img {
    margin-top: 1rem;
  }

  ol li:before {
    font-size: 18px;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.img-text-columm {
  display: flex;
  margin-bottom: 6rem;
}

.img-text-columm:last-child {
  margin-bottom: 0;
}

.img-text-columm img {
  max-width: 12.5rem;
  margin-bottom: 2rem;
}

.img-text-columm.img-text-columm-right {
  justify-content: flex-end;
}

.img-text-columm > div {
  max-width: 55%;
}

@media screen and (max-width: 1440px) {
  .img-text-columm > div {
    max-width: 100%;
  }
}

@media screen and (max-width: 896px) {
  .img-text-columm {
    margin-bottom: 3rem;
  }
  .img-text-columm.img-text-columm-right {
    justify-content: flex-start;
  }
  .img-text-columm img {
    max-width: 8.75rem;
    margin-bottom: 1.5rem;
  }
}

.blockquote-full blockquote {
  margin: 24px 0 40px 0;
}

.blockquote-full svg {
  width: auto;
}

.blockquote-full .avatar-container {
  margin-top: 0.5em;
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.blockquote-full .avatar-container p {
  margin: 0;
  font-family: "qooa-font";
  font-weight: 600;
  font-size: 24px;
  line-height: 125%;
}

.blockquote-full .avatar {
  vertical-align: middle;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  filter: grayscale(1);
  margin-right: 20px;
}

@media screen and (max-width: 1440px) {
  .blockquote-full .avatar-container p {
    font-size: 20px;
  }
}

@media screen and (max-width: 896px) {
  .blockquote-full blockquote {
    margin: 12px 0 20px 0;
  }
  .blockquote-full svg {
    width: 32px;
  }
  .blockquote-full .avatar-container p {
    font-size: 16px;
  }
  .blockquote-full .avatar {
    width: 72px;
    height: 72px;
  }
}

footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 2rem;
}
footer .logo-container {
  width: 95%;
  margin: auto;
}

footer svg {
  width: 100%;
  height: 100%;
}

.copyright-container {
  display: flex;
  color: white;
  margin-top: 3rem;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 896px) {
  .copyright-container {
    font-size: 16px;
  }
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

.hidden,
[hidden] {
  display: none !important;
}

.show {
  display: block;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}
